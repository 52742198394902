import React, {useEffect, ChangeEvent, useState} from "react";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
// list
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

// toggle button
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

// for styles
import {
  Button,
  Backdrop
} from "@material-ui/core/";

import {
  getFlightCodeLabel,
  getFlightCodeByLabel,
  getFlightTime,
  getMultiAirports,
  isVacancy,
  getFlightNumberForDisplay,
  getArrivalAirports,
} from "../../utils/flight";

// loading
import LoadingOverlay from 'react-loading-overlay-ts';

// sass
import "../../scss/common.scss";
import "../../scss/component.scss";
import "../../scss/component/form.scss";
import "../../scss/component/icon.scss";

import Modal from "@material-ui/core/Modal";
import {
  Flights,
  FlightType,
  FReservationFlightEntity,
  FReservationFlightsEntity
} from "../../types/Flight";

// for api
import { signalApiClient } from "../../utils/apiClient";
import { PlanSearch } from "../../types/Search";
import dayjs from "dayjs";

import {airportGroup, departureAirCode} from "../../config/Airport";
import {addOtsRate, basicTotalPriceChangeForFlight} from "../../utils/price";
import {
  CheepsFlight,
  flightSearchResponse,
  FReservationConnectionFlight, FReservationFlight,
  FReservationFlights
} from "../../types/FlightV2";
import {
  createReserveFlightNumber,
  filterFlightTime,
  getCheepsFlight,
  getConnectionFlights,
  getDiffFlightPrice, getFlightClassLabel, isChangeAirport,
  isConnection, lastCurrentFlight,
  sortFlights
} from "../../utils/flightV2";
import {PlanSelectFormData} from "../../types/planSelectForm";
import {hotelList} from "../../types/Hotel";
import _ from "lodash";

// モーダルに何か渡したい場合はここ追加する
type Props = {
  planSelectFormData: PlanSelectFormData,
  pageRef: string,
  isShow: boolean,
  callback: () => void,
  changeAirportCallback: (airport:string, action: string) => void,
  search: PlanSearch | undefined,
  currentFlights: FlightType | undefined,
  changeFlightCallback: (airline:string, action:string, currentFlights: FReservationFlight[], airport: string | null | undefined ) => void,
  profitRate: string | undefined,
}

const FlightModalApi: React.FC<Props> = ({
    planSelectFormData,
    pageRef,
    isShow,
    callback,
    changeAirportCallback,
    changeFlightCallback,
    search,
    currentFlights,
    profitRate,
  }) => {
  const [controller, setController] = useState(new AbortController())
  
  // 空港の配列
  const [airports, setAirports] = React.useState<string[]>();
  const [from , setFrom] = React.useState(search?.from ?? '');

  // ローディング
  const [isLoading, setLoading] = React.useState(false);
  const [changeSearch, setChangeSearch] = React.useState(false);

  // 全てのフライト
  const [flights, setFlights] = React.useState<FReservationFlights[] | undefined>(undefined);
  const [defaultFlights, setDefaultFlights] = React.useState<FReservationFlights[] | undefined>();

  // 選択（更新やコールバックで渡す用）
  const [selectedFlights, setSelectedFlights] = React.useState<FReservationFlight>();

  const [airport, setAirport] = React.useState<string | null | undefined>();
  const [arrivals, setArrivals] = React.useState<string[]>();     // 到着空港

  // all,direct,connecting
  const [flightFilter, setFlightFilter] = React.useState("all");

  // 「確定する」ボタンのハンドリング
  const [buttonEnable, setButtonEnable] = React.useState(false);
  const [cheepsFlight, setCheepsFlight] = React.useState<CheepsFlight>({
    flight: undefined,
    index: 0,
    price: 0
  });

  // 見つからない場合のメッセージ
  const [mixedMessage, setMixedMessage] = React.useState("データが見つかりませんでした。");
  const [connectingMessage, setConnectingMessage] = React.useState("データが見つかりませんでした。");

  useEffect(() => {
    if (isShow && search) {
      initFlightSelect();
      if (search.c_airport_to && search.isChange && currentFlights?.action === 'outbound') {
        setAirport(search.c_airport_to);
        setArrivals(getArrivalAirports(search.c_airport_to, true, false));
      } else {
        setAirport(search.from);
        setArrivals(getArrivalAirports(search.from, true, false));
      }
      (async () => {
        await searchFlights();
      })()
    }
  }, [isShow])

  useEffect(() => {
    if (changeSearch) {
      (async () => {
        await searchFlights();
      })()
      setChangeSearch(false)
    }
  }, [changeSearch]);
  
  const searchFlights = async () => {

    if (search !== undefined && isShow) {
      initFlightSelect();

      // フライト
      if(currentFlights && currentFlights.currentNumbers && currentFlights.currentNumbers.length > 0) {
        setLoading(true)

        // 復路
        const outboundTo = () => {
          const result = search?.isChange ? getFlightCodeByLabel(search?.c_airport_to) : getFlightCodeByLabel(search?.from)
          return airport ? getFlightCodeByLabel(airport) : result
        }
        // 往路
        const inboundFrom = () => {
          const result = getFlightCodeByLabel(search?.from)
          return airport ? getFlightCodeByLabel(airport) : result
        }

        // comb
        const from = currentFlights.action === "inbound" ? inboundFrom() : search?.to
        const to = currentFlights.action === "inbound" ? search?.to : outboundTo()

        setAirports(getMultiAirports(from, true, currentFlights.action == "inbound"));

        const useParam = {
          "depapo": from,
          "arrapo": to,
          "departure_date": currentFlights.action === "inbound" ? (search?.start).replace(/-/g, "") : (search?.end).replace(/-/g, "")// "20220622" format
        }

        const abortController = new AbortController()
        setController(abortController);
        await signalApiClient(abortController).post('/air/flight-search', JSON.stringify({
          "action": currentFlights.action,
          "inboundDepartureDate": useParam.departure_date,
          "outboundDepartureDate": useParam.departure_date,
          "inboundDepapo": useParam.depapo,
          "inboundArrapo": useParam.arrapo,
          "outBoundDepapo": useParam.depapo,
          "outBoundArrapo": useParam.arrapo,
          "air_code": currentFlights.airline
        })).then((response: AxiosResponse<flightSearchResponse>) => {
          // Loading
          setLoading(false)
          const fs = response.data;
          if (fs.status === 200 && fs.flight && fs.flight.FReservationFlights ) {
            let _flights = sortFlights(fs.flight.FReservationFlights)
            
            // フライト時間制御チェック(絞り込み)
            if (currentFlights.action === "inbound") {
              _flights = filterFlightTime(fs.flight.FReservationFlights, planSelectFormData.sellerProduct?.arrivalTimeControl, "arrival");
            } else if (currentFlights.action === "outbound") {
              _flights = filterFlightTime(fs.flight.FReservationFlights, planSelectFormData.sellerProduct?.departureTimeControl, "departure");
            }

            const cheeps = getCheepsFlight(currentFlights.airline, _flights, planSelectFormData.searchParams.roomDetail);
            setCheepsFlight({...cheeps})
            setFlights([..._flights]);
            setDefaultFlights([..._flights]);

            if (_flights.length > 0 && isChangeAirport(search, currentFlights.action, from)) {
              setSelectedFlights(_flights[cheeps.index].FReservationFlight[0]);
            } else if (_flights.length === 0) {
              setSelectedFlights(undefined);
            }
            setButtonEnable(true);
          } else {
            setSelectedFlights(undefined);
            setFlights([]);
          }

        })
          .catch(function (error) {
            setLoading(false)
            setSelectedFlights(undefined);
            setFlights([]);
            // TODO: handle error
            console.log(error);
          });
      }
    }
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const flightOpen = Boolean(anchorEl);

  const handleMenuItemClick = (
      e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    if (airport !== e.target.value) {
      setAnchorEl(null);
      const updateAirport = e.target.value;
      setAirport(updateAirport)
      setFrom(updateAirport)
      setChangeSearch(true)
    }
  };

  const isInbound = ():boolean => {
    if(currentFlights && airport) {
      return currentFlights.action == 'inbound'
    } else {
      return false
    }
  }

  const isSelected = (checkFlight: FReservationFlight): boolean => {
    const checkFlightNumber = createReserveFlightNumber(checkFlight);
    const selectFlightNumber = createReserveFlightNumber(selectedFlights);
    return checkFlightNumber === selectFlightNumber;
  }

  /**
   * 初期フライト選択設定
   * @param currentFlightss
   */
  const initFlightSelect = () => {
    setFlights(undefined);
    if(currentFlights == null) return;
    setSelectedFlights({...currentFlights.flight[0]});

  }

  const handleFlightSelect = (selectedFlights: FReservationFlight | null, isMixed: boolean, airline: string|undefined) => {
    if(selectedFlights == null || !flights) return;
    setSelectedFlights({...selectedFlights})
    if(flights.length > 0) {
      setConnectingMessage("データが見つかりませんでした。")
    }
  }

  //直行/乗継ぎ用トグルボタン
  const toggleCreatecurrentFlights = (
      label: string,
      value: string,
  ) => {
    return { label, value };
  };
  const toggleRows = [
    toggleCreatecurrentFlights("すべて", "all",),
    toggleCreatecurrentFlights("直行便のみ", "direct",),
    toggleCreatecurrentFlights("乗継便のみ", "connecting",),
  ];
  const [flightToggleState, setFlightToggleState] = React.useState(() => ['all']);
  const handleFlightToggle = (event: React.MouseEvent<HTMLElement>, newFlightToggle: string[]) => {
    setFlightToggleState(newFlightToggle);
    setFlightFilter(newFlightToggle.toString());
    if(defaultFlights && defaultFlights.length > 0) {
      // 乗継便
      if (newFlightToggle.toString() === "connecting") {
        setFlightFilter("connecting");
      }
      // 直行便
      if (newFlightToggle.toString() === "direct") {
        setFlightFilter("direct");
      }
      // すべて
      if (newFlightToggle.toString() === "all") {
        setFlightFilter("all");
      }
    }
  }

  //出発時間用トグルボタン
  const toggleTimeCreatecurrentFlights = (
    label: string,
    value: string,
    fromTime: string,
    toTime: string,
  ) => {
    return { label, value, fromTime, toTime };
  };
  const toggleTimeRows = [
    toggleTimeCreatecurrentFlights("すべて", "all", "", ""),
    toggleTimeCreatecurrentFlights("5時〜8時台", "5_to_8", "0500", "0859"),
    toggleTimeCreatecurrentFlights("9時〜12時台", "9_to_12", "0900", "1259"),
    toggleTimeCreatecurrentFlights("13時〜17時台", "13_to_17", "1300", "1759"),
    toggleTimeCreatecurrentFlights("18時〜22時台", "18_to_22", "1800", "2259"),
  ];
  const [flightTimeToggleState, setFlightTimeToggleState] = React.useState(() => ['all']);
  const handleFlightTimeToggle = (event: React.MouseEvent<HTMLElement>, newFlightTimeToggle: string[]) => {
    setFlightTimeToggleState(newFlightTimeToggle);
  };

  /**
   * 絞り込み
   */
  useEffect(() => {
    let updateFlights = _.cloneDeep(defaultFlights);

    if (updateFlights) {

      // 乗継便
      if(flightFilter === "connecting") {
        setFlightFilter("connecting");
        updateFlights = updateFlights.filter((flight) => {
          return flight.FReservationFlight[0].FReservationConnectionFlight.length > 0
        })
      }
      // 直行便
      if(flightFilter === "direct") {
        setFlightFilter("direct");
        updateFlights = updateFlights.filter((flight) => {
          return flight.FReservationFlight[0].FReservationConnectionFlight.length === 0
        })
      }


      if(flightTimeToggleState) {
        const result = toggleTimeRows.find((t) => t.value === flightTimeToggleState.toString())
        if (result && result.value !== "all") {
          updateFlights = updateFlights.filter((flights) => {
            return Number(flights.FReservationFlight[0].dep) >= Number(result.fromTime) && Number(flights.FReservationFlight[0].dep) <= Number(result.toTime);
          })
        }
      }

      setFlights([...updateFlights])
    }

  }, [flightTimeToggleState, flightFilter])

  // 往路なら出発日を、復路なら到着日を取得する
  const getFlightScheduleDate = () => {
    if (search && currentFlights) {
      return currentFlights.action === "inbound" ? search.start : search.end
    }
    return search?.start;
  }

  /**
   * フライト確定ボタン時処理
   */
  const decide = () => {
    setFlights(undefined)
    setFlightFilter('all')
    setFlightToggleState(['all']);
    setFlightTimeToggleState(['all']);
    if(!buttonEnable || !selectedFlights) {
      callback()
      return
    }
    if(currentFlights && selectedFlights) {
      // 出発・到着空港変更時 更新処理
      if(airport) {
        changeFlightCallback(currentFlights.airline,  currentFlights.action, [selectedFlights], airport)
      } else {
        // セクション部分の更新
        changeFlightCallback(currentFlights.airline,  currentFlights.action, [selectedFlights], airport)
      }
    }
  }

  // for text label
  const getFlightTextLabel = () => {
    if(currentFlights) {
      // 往路
      if(currentFlights.action === "inbound") {
        return "往路"
      }
      // 復路
      if(currentFlights.action === "outbound") {
        return "復路"
      }
    }
    return "往路"
  }

  const reset = () => {
    controller.abort();
    setFlights(undefined)
    setFlightFilter('all')
    setFlightToggleState(['all']);
    setFlightTimeToggleState(['all']);
    if (currentFlights) {
      setAirport(undefined);
      setFrom(search?.from ?? '')
      setSelectedFlights({...currentFlights.flight[0]})
      setFlights(undefined);
    }
    callback();
  }

  return (
    <>
      {/* モーダル：フライト選択 */}
      <div>
        <Modal
          open={isShow}
          onClose={reset}
          className="modal"
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 300,
          }}
        >
          <div className="modal-fixed">
            <div className="modal-fixed-heading">
              <p className="modal-fixed-heading-text">{getFlightTextLabel()}のフライト変更</p>
              <p className="modal-fixed-close">
                <Button onClick={callback} className="modal-fixed-close-button">
                  <i className="modal-fixed-close-button-icon"></i>閉じる
                </Button>
              </p>
            </div>

            <div className="flight-modal-fixed-content">
              <div className="wrap-content-900 wrap-padding-md">
                <p className="heading-2 fz-24-md mt-24 mb-24 mt-40-md">{currentFlights?.airline}から選択</p>
                <dl>
                  <div className="d-f-md ai-c">
                    <dt className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md fx-sh w-110-md">利用空港</dt>
                    <dd className="fx-1">
                      <div className="d-f ai-e">
                        {!isInbound() && // アウトバウンドだったら
                          <>
                            <div>
                              <p className="c-blue_gray fz-12 fz-14-md mb-5">出発空港</p>
                              <div className="form-select_list-wrap">
                                <p className="form-select_list-text">{getFlightCodeLabel(search?.to)}</p>
                              </div>
                            </div>
                            <i className="icon-right-arrow fx-sh fz-12 ml-16 mr-16 mb-16"></i>
                          </>
                        }
                        <div>
                          <p className="c-blue_gray fz-12 fz-14-md mb-8">{isInbound() ? '出発空港' : '到着空港'}</p>
                          <div className="form-select_list-wrap">
                            {isInbound() && currentFlights?.page === "confirm" ?
                              <>
                                <p className="form-select_list-text">{getFlightCodeLabel(search?.from)}</p>
                              </>
                              : isInbound() ?
                                <>
                                  <div className="select_box-default">
                                    <select className={"form-select_list"}
                                            name="prefecture"
                                            value={airport ?? ""}
                                            onChange={(e) => handleMenuItemClick(e)}
                                    >
                                      {
                                        // 出発空港
                                        airportGroup && airportGroup.map((currentFlights: any, index: number) => (
                                          <optgroup label={currentFlights.groupName} key={index}>
                                            {Object.keys(currentFlights.airportList).map((aircode: any, i: number) => (
                                              <option className={"form-select_list-options"} value={aircode}
                                                      key={i}>{currentFlights.airportList[aircode]}</option>
                                            ))}
                                          </optgroup>
                                        ))
                                      }
                                    </select>
                                  </div>
                                </>
                                :
                                // 出発空港
                                <>
                                  <div className="select_box-default">
                                    <select className={"form-select_list"}
                                            name="prefecture"
                                            value={airport ?? ""}
                                            onChange={(e) => handleMenuItemClick(e)}
                                    >
                                      {
                                        arrivals ? arrivals.map((airCode, index) => (
                                          <option key={"c_airport" + index} value={airCode}>
                                            {getFlightCodeLabel(airCode)}
                                          </option>
                                        )) : ""
                                      }
                                    </select>
                                  </div>
                                </>
                            }
                          </div>
                        </div>

                        {isInbound() && // インバウンドだったら
                          <>
                            <i className="icon-right-arrow fx-sh fz-12 ml-16 mr-16 mb-16"></i>
                            <div className="fx-1">
                              <p className="c-blue_gray fz-12 fz-14-md mb-5">到着空港</p>
                              <div className="form-select_list-wrap">
                                <p className="form-select_list-text">{getFlightCodeLabel(search?.to)}</p>
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </dd>
                  </div>

                  <div className="d-f-md ai-c mt-16 mt-24-md">
                    <dt className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md fx-sh w-110-md">直行/乗継ぎ</dt>
                    <dd className="fx-1">
                      <ToggleButtonGroup
                        value={flightToggleState}
                        exclusive
                        onChange={handleFlightToggle}
                        aria-label="all"
                        className="box-toggle_button"
                      >
                        {toggleRows.map((row, index) => (
                          <ToggleButton key={"toggle_f" + index} value={row.value} aria-label={row.value}
                                        className="box-toggle_button-item">
                            {row.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </dd>
                  </div>

                  <div className="d-f-md ai-c mt-16 mt-24-md">
                    <dt className="c-navy fz-13 fz-16-md fw-b mb-8 mb-0-md fx-sh w-110-md">出発時間帯</dt>
                    <dd className="fx-1">
                      <ToggleButtonGroup
                        value={flightTimeToggleState}
                        exclusive
                        onChange={handleFlightTimeToggle}
                        aria-label="all"
                        className="box-toggle_button"
                      >
                        {toggleTimeRows.map((row, index) => (
                          <ToggleButton key={"toggle_t" + index} value={row.value} aria-label={row.value}
                                        className="box-toggle_button-item">
                            {row.label}
                          </ToggleButton>
                        ))}
                      </ToggleButtonGroup>
                    </dd>
                  </div>
                </dl>

                {/* フライト選択 */}
                <LoadingOverlay
                  active={isLoading}
                  spinner
                  className="loading-small-height"
                >

                  <form action="">
                    {/* 直行便 */}
                    {flightToggleState ?
                      <div className="box_select-flight-schedule label-mulch">

                        <ul className="list-note mb-50">
                          <li>フライト差額は、選択済みフライトからの差額（1人あたり）を目安として表示しています。最終的な旅行代金は、旅行代金合計にてご確認ください。</li>
                          {currentFlights?.airline === "ANA" &&
                            <>
                              <li>プレミアムクラスは選択できません。</li>
                              <li>共同運航便（コードシェア便）について<a
                                href="https://www.ana.co.jp/ja/jp/travel/dom_pkg/info/codeshare/" target="_blank"
                                rel="noopener noreferrer">詳しくはこちら</a></li>
                            </>
                          }
                          {currentFlights?.airline === "JAL" &&
                            <>
                              <li>ファーストクラスは選択できません。</li>
                            </>
                          }
                        </ul>

                        <div className="box_select-flight-schedule-title">
                          <p
                            className="box_select-flight-schedule-date">{dayjs(getFlightScheduleDate(), "YYYYMMDD").format('YYYY年MM月DD日（ddd）')}</p>
                          <div className="box_select-flight-schedule-difference">差額
                            <span className="fz-10 fz-12-md">（１人あたり）</span>
                          </div>
                        </div>

                        <ul className="box_select-flight-schedule-list">
                          {flights && flights.length > 0 ? flights?.map((flight, fIndex) => (<>
                            <li
                              className={flight.FReservationFlight.length > 1 ? "box_select-flight-schedule-list-item mulch" : "box_select-flight-schedule-list-item"}>
                              <div className="box_select-flight-schedule-list-item-time_table">
                                {isConnection(flight.FReservationFlight[0]) ? (<>
                                  <div className="box_select-flight-schedule-list-item-time_table-label orange">
                                    <span
                                      className="box_select-flight-schedule-list-item-time_table-label-text">乗継便</span>
                                  </div>
                                </>) : (<>
                                  <div className="box_select-flight-schedule-list-item-time_table-label green">
                                    <span
                                      className="box_select-flight-schedule-list-item-time_table-label-text">直行便</span>
                                  </div>
                                </>)}

                                <div className="box_select-flight-schedule-list-item-time_table-time_schedule">
                                  <ul className="box_select-flight-schedule-list-item-time_table-time_schedule-list">
                                    <li key={"flight-" + fIndex}
                                        className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item">
                                      <p
                                        className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-heading">{getFlightNumberForDisplay(currentFlights?.airline, flight.FReservationFlight[0].air_code, flight.FReservationFlight[0].air_sub_no)}</p>
                                      <ul
                                        className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time">
                                        <li key={"flight-dep-" + fIndex}
                                            className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time-item">
                                          <span
                                            className="fz-10 fz-12-md w-5em">{getFlightCodeLabel(flight.FReservationFlight[0].depapo)?.replace('空港', '')}</span><span
                                          className="fz-18 fz-24-md c-navy fw-b mb-2 mb-4-md">{getFlightTime(flight.FReservationFlight[0].dep)}</span>
                                        </li>

                                        <li key={"flight-arv-" + fIndex}
                                            className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time-item">
                                          <span
                                            className="fz-10 fz-12-md w-5em">{getFlightCodeLabel(flight.FReservationFlight[0].arrapo)?.replace('空港', '')}</span><span
                                          className="fz-14 fz-20-md c-navy fw-b mb-4 mb-6-md">{getFlightTime(flight.FReservationFlight[0].arv)}</span>
                                        </li>
                                      </ul>
                                    </li>

                                    {getConnectionFlights(flight.FReservationFlight[0].FReservationConnectionFlight, []).map((connectionFlight, cIndex) => (<>

                                      <li key={"flight-connection-" + cIndex}
                                          className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item">
                                        <p
                                          className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-heading">{getFlightNumberForDisplay(currentFlights?.airline, connectionFlight.air_code, connectionFlight.air_sub_no)}</p>
                                        <ul
                                          className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time">
                                          <li key={"flight-connection-dep-" + cIndex}
                                              className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time-item">
                                            <span
                                              className="fz-10 fz-12-md w-5em">{getFlightCodeLabel(connectionFlight.depapo)?.replace('空港', '')}</span>
                                            <span
                                              className="fz-18 fz-24-md c-navy fw-b mb-2 mb-4-md">{getFlightTime(connectionFlight.dep)}</span>
                                          </li>

                                          <li key={"flight-connection-arv-" + cIndex}
                                              className="box_select-flight-schedule-list-item-time_table-time_schedule-list-item-time-item">
                                            <span
                                              className="fz-10 fz-12-md w-5em">{getFlightCodeLabel(connectionFlight.arrapo)?.replace('空港', '')}</span>
                                            <span
                                              className="fz-14 fz-20-md c-navy fw-b mb-4 mb-6-md">{getFlightTime(connectionFlight.arv)}</span>
                                          </li>
                                        </ul>
                                      </li>
                                    </>))}
                                  </ul>
                                </div>

                              </div>
                              <div className="box_select-flight-schedule-list-item-seat">
                                {flight.FReservationFlight.map((FReservationFlight, seatIndex) => (<>
                                  <div
                                    className={isVacancy(currentFlights?.airline, FReservationFlight, planSelectFormData.searchParams.roomDetail) ? 'box_select-flight-schedule-list-item-seat-content' : 'box_select-flight-schedule-list-item-seat-content invalid'}>
                                    <ul className="box_select-flight-schedule-list-item-seat-content-list">
                                      <li key={"flight-input-" + fIndex + "-" + seatIndex}
                                          className="box_select-flight-schedule-list-item-seat-content-list-label">
                                        <input
                                          type="radio"
                                          name="df"
                                          id={"flight-input-id" + fIndex + "-" + seatIndex}
                                          value={createReserveFlightNumber(FReservationFlight)}
                                          checked={isSelected(FReservationFlight)}
                                          disabled={!isVacancy(currentFlights?.airline, FReservationFlight, planSelectFormData.searchParams.roomDetail)}
                                          onChange={(event) => handleFlightSelect(FReservationFlight ? FReservationFlight : null, true, currentFlights?.airline)}
                                        />
                                        <label htmlFor={"flight-input-id" + fIndex + "-" + seatIndex}
                                               className="fz-12 fz-14-md c-navy fw-b">{getFlightClassLabel(FReservationFlight.class_code)}</label>
                                      </li>
                                      <li className="box_select-flight-schedule-list-item-seat-content-list-status">
                                        <label htmlFor={"flight-input-id" + fIndex + "-" + seatIndex}
                                               className="box_select-flight-schedule-list-item-seat-content-list-status-label">
                                          <span className="d-b fz-10 fz-14-md fw-b c-navy">空席</span><i
                                          className={isVacancy(currentFlights?.airline, FReservationFlight, planSelectFormData.searchParams.roomDetail) ? 'icon-possible ml-8-md' : 'icon-impossible ml-8-md'}></i></label>
                                      </li>
                                      <li key={"flight-price-" + fIndex + "-" + seatIndex}
                                          className="box_select-flight-schedule-list-item-seat-content-list-result">
                                        <label htmlFor={"flight-input-id" + fIndex + "-" + seatIndex}><p
                                          className="box_select-flight-schedule-list-item-seat-content-list-result-text">{getDiffFlightPrice(FReservationFlight, currentFlights, planSelectFormData)}<span
                                          className="fz-12">円</span></p></label>
                                      </li>
                                    </ul>
                                  </div>
                                </>))}
                              </div>
                            </li>
                          </>)) : (<li key={"dnocurrentFlights"} className="p-16">{mixedMessage}</li>)}
                        </ul>
                      </div>

                      : <></>}

                    <div className="flight-modal-fixed-bottom">
                      {/* <Button disabled={buttonEnable} onClick={() => decide()} className="button-medium-light_blue padding-large w-100per">確定する</Button> */}
                      <button type="button" onClick={() => decide()}
                              className="button-medium-orange padding-large w-100per">確定する
                      </button>

                      <div className="wrap-content-900-no_padding ">
                        <div className="box-white fixed-padding">
                          <div className="d-f ai-c jc-sb jc-fe-md">
                            <p className="fw-b pr-24">
                              現在の旅行代金合計<br/>
                              <span className="fz-12 fw-n">（※申込人数分の総額）</span>
                            </p>
                            <p className="fz-24 fz-28-md c-red fw-b">
                              {planSelectFormData.basicPriceTotal > 0 ? (
                                <>
                                  {basicTotalPriceChangeForFlight(planSelectFormData, {flight: selectedFlights, bound: isInbound() ? 'inbound' : 'outbound'})?.toLocaleString()}
                                  <span className="fz-14 fz-16-md">円(税込)</span>
                                </>
                              ) : (<p className="fw-b">計算中</p>)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </LoadingOverlay>
              </div>
            </div>

          </div>
        </Modal>
      </div>
      {/* モーダルフライト変更 */}
    </>
  );
};

export default FlightModalApi;
